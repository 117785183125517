// The HTTP (non-S) URLs are only for local development.
// noinspection HttpUrlsUsage
export const getConfig = () => ({
  account: {
    url:
      process.env.REACT_APP_ACCOUNT_URL ||
      'https://authentication.stg.platform.basworld.com',
  },
  environment: process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV,
  esb: {
    url: process.env.REACT_APP_ESB_URL || 'https://staging-api.bastrucks.com',
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  static: {
    url: process.env.REACT_APP_STATIC_URL || 'https://static.basworld.com',
  },
  vehicle: {
    url:
      process.env.REACT_APP_VEHICLE_URL ||
      'https://staging-vehicle.api.basworld.com',
  },
  web: {
    url: process.env.REACT_APP_WEB_URL || 'https://staging-www.basworld.com',
    documentsUrl:
      process.env.REACT_APP_WEB_DOCUMENT_URL || 'https://www.basworld.com',
    cookiesDomain: process.env.REACT_APP_WEB_COOKIES_DOMAIN || 'basworld.com',
  },
  google: {
    recaptchaSiteKey:
      process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
      '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  },
  log: {
    path: '/gateway/log',
  },
});
